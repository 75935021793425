<template>
  <div class="card">
    <div class="card-body text-center d-flex align-items-center flex-column">
      <div class="card--cae--content mb-auto">
        <!-- <div class="mb-1">
          <img
            src="../../../../app-assets/images/escudo-seguro.png"
            alt="name category"
            class="round"
            height="40"
            width="40"
          >
        </div> -->
        <h3>{{ section.name }}</h3>
        <template v-if="section.description">
          <p
            class="mb-0 control-img mb-50"
            v-html="displayedText"
          />
          <a
            v-if="totalChars == charLimit"
            class="btn btn-secondary"
            href="javascript:;"
            @click="isShowingMore = !isShowingMore;"
          >
            {{ buttonText }}
          </a>
        </template>
        <p class="text-muted">
          <template v-if="section.subsections.length > 0">
            {{ section.subsections.length }} subsections
          </template>
          <template v-if="section.subsections.length > 0 && section.documents > 0">
            /
          </template>
          <template v-if="section.documents > 0">
            {{ section.documents }} documents
          </template>
        </p>
        <div
          v-if="section.subsections.length > 0"
          class="mb-1"
        >
          <v-select
            class="center-placeholder"
            label="name"
            :options="section.subsections"
            :get-option-key="option => option.name"
            :placeholder="'Access to subsection'"
            @input="enterSection($event)"
          />
        </div>
      </div>
      <a
        v-if="section.documents > 0"
        class="btn btn-small btn-primary"
        :href="$router.resolve({ name: getCurrentRouteName, params: {id: section.id}, query: {host: (institution ? institution.id : (loggedUser.current_host ? loggedUser.current_host.id : null)), actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser} }).href"
        target="_blank"
      >View documents</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {
      isShowingMore: false,
      charLimit: 500,
      totalChars: 0,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      institution: 'cae/institution',
      loggedUser: 'auth/admin',
    }),
    buttonText() {
      return this.isShowingMore ? 'Show less' : 'Show more'
    },
    displayedText() {
      const div = document.createElement('div')
      div.innerHTML = this.section.description

      if (this.isShowingMore || div.innerText.length < this.charLimit) {
        return this.section.description
      }

      // Get the HTML portion that corresponds to this truncated text
      return this.extractFirstCharacters()

      // return this.getHtmlTruncated(div.innerText.slice(0, this.charLimit)) + (div.innerText.length > this.charLimit ? '...' : '')
    },
    getCurrentRouteName() {
      if (localStorage.getItem('currentRoute').includes('admin.occupational-hazards')) {
        return 'admin.occupational-hazards.view'
      }

      return 'cae.view'
    },
  },
  mounted() {},
  methods: {
    enterSection(section) {
      const url = this.$router.resolve({
        name: this.getCurrentRouteName,
        params: { id: section.id },
        query: {
          host: (this.institution ? this.institution.id : null),
          actAs: this.$route.query.actAs,
          prefilterUser: this.$route.query.prefilterUser,
        },
      }).href

      window.open(url, '_blank')
    },
    extractFirstCharacters() {
      // Create a temporary container to manipulate the HTML content
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = this.section.description

      const limit = this.charLimit
      const root = tempDiv
      let charCount = 0
      let result = ''

      // Function to recursively traverse nodes
      const traverse = node => {
        if (charCount >= limit) return

        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent
          if (charCount + text.length > limit) {
            // Add only the portion of the text that fits within the limit
            result += `${text.substring(0, limit - charCount)} ...`
            charCount = limit
          } else {
            result += text
            charCount += text.length
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          // Add the opening tag with attributes
          result += `<${node.tagName.toLowerCase()}`
          node.attributes.forEach(attr => {
            result += ` ${attr.name}="${attr.value}"`
          })
          result += '>'

          // Traverse child nodes
          node.childNodes.forEach(child => {
            traverse(child)
          })

          // Close the tag
          if (!['img', 'br', 'hr'].includes(node.tagName.toLowerCase())) {
            result += `</${node.tagName.toLowerCase()}>`
          }
        }
      }

      // Start traversing the content
      traverse(root)

      this.totalChars = charCount

      // Set the trimmed HTML
      return result
    },
  },
}
</script>
