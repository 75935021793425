<template>
  <ConvoFilter
    :filter-path="'-'"
    :filter-token="filterToken"
    @appliedFilters="appliedFilters($event)"
  >
    <template #filters>

      <div class="mb-1">
        <label
          for="nameseniorcall"
          class="form-label"
        >Researcher</label>
        <v-select
          v-model="filters.user"
          label="name"
          :options="users"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
        />
      </div>
      <hr>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Document title</label>
        <input
          v-model="filters.title"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Upload by</label>
        <v-select
          v-model="filters.upload_by"
          label="name"
          :options="[
            {name: 'All', value: ''},
            {name: 'ICREA', value: 'icrea'},
            {name: 'Host', value: 'host'},
          ]"
          :get-option-key="option => option.value"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Department</label>
        <v-select
          v-model="filters.department"
          label="name"
          :options="departments"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'institutions/fetchDepartments')"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Workplaces</label>
        <v-select
          v-model="filters.workplace"
          label="name"
          :options="workplaces"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'workplaces/filter')"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Hazards</label>
        <v-select
          v-model="filters.hazard"
          label="name"
          :options="hazards"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'hazards/filter')"
        />
      </div>

      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >New doc</label>
        <v-select
          v-model="filters.new_doc"
          label="name"
          :options="[
            {name: 'All', value: null},
            {name: 'Yes', value: true},
            {name: 'No', value: false},
          ]"
          :get-option-key="option => option.value"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Renew</label>
        <v-select
          v-model="filters.renew_year"
          label="name"
          :options="[
            {name: 'All years', value: null},
            {name: '2026', value: 2026},
            {name: '2025', value: 2025},
            {name: '2024', value: 2024},
            {name: '2023', value: 2023},
            {name: '2022', value: 2022},
            {name: '2021', value: 2021},
            {name: '2020', value: 2020},
          ]"
          :get-option-key="option => option.value"
        />
      </div>
      <!-- <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Status</label>
        <v-select
          v-model="filters.status"
          label="name"
          :options="[
            {name: 'All', value: null},
            {name: 'Active', value: 'active'},
            {name: 'Archived', value: 'archived'},
          ]"
          :get-option-key="option => option.value"
        />
      </div> -->
      <hr>
      <h5>Upload</h5>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >From</label>
        <date-picker
          v-model="filters.from"
          format="D MMM Y"
          value-type="format"
          class="w-100"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >To </label>
        <date-picker
          v-model="filters.to"
          format="D MMM Y"
          value-type="format"
          class="w-100"
        />
      </div>
    </template>
  </ConvoFilter>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import ConvoFilter from '../../partials/offcanvas/ConvoFilter.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    ConvoFilter,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      filterToken: null,
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'users/fakeUsers',
      departments: 'institutions/departments',
      hazards: 'hazards/hazards',
      workplaces: 'workplaces/workplaces',
    }),
  },
  async mounted() {
    this.filters = this.$route.params.filters ?? {}
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async appliedFilters() {
      this.$router.push({ name: 'cae.filter', params: { filters: this.filters } })
      this.$emit('appliedFilters', this.filters)
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
