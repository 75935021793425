<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ section.name }}<br>
                <small v-if="institution"><span class="badge bg-light-primary dis-ib">{{ institution.name }}</span></small> <!-- solo admin -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
              @click="hasHistory ? $router.go(-1) : (localStorage.getItem('currentRoute').includes('admin.occupational-hazards') ? $router.push({name: 'admin.occupational-hazards.index'}) : $router.push({name: 'cae.index'}))"
            ><i data-feather="chevron-left" /></a>
            <span
              class="dis-ib ms-50"
              title="Search documents"
              @click="openFilters"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span>
            <span
              class="dis-ib ms-50"
              title="Export"
              @click="$store.dispatch('modals/toggleCaeExport', true)"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="file" /></a>
            </span>
            <a
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')"
              class="btn btn-icon btn-primary ms-50"
              :href="$router.resolve({ name: 'cae.labour-hazards.create', query: { section: section.id, parent: (section.parent ? section.parent.id : null), host: filters.host.id, actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser } }).href"
              target="_blank"
            ><i data-feather="upload" /></a>
          </div>
        </div>
      </div>
      <hr>
      <div
        class="content-body content-body--senior-call"
      >
        <template v-if="section.subsections && section.subsections.length != 0">
          <h3 class="mb-2">
            {{ section.subsections.length }} subsections
          </h3>

          <div class="row row--cae--card align-items-stretch">
            <div
              v-for="subsection in section.subsections"
              :key="subsection.id"
              class="col-sm-4 row-cae-card--container"
            >
              <CaeSection :section="subsection" />
            </div>
          </div>
        </template>
        <hr v-if="section.subsections && section.subsections.length != 0">
        <div class="small-table">
          <CompleteTable
            :sorted-field="sortedField"
            :data="active"
            :sort-mapping="sortMappingActive"
            :title="'active documents'"
            :total="active.length"
            :fetch-path="''"
            :export-path="''"
            :filter-path="''"
            :hide-bottom="true"
          >
            <template v-slot="{field}">
              <td class="text-end">
                <template v-if="field.need_feedback_bool">
                  <a
                    v-if="field.response && field.response.response"
                    :href="field.response.response.url"
                    target="_blank"
                    class="me-50"
                  >
                    <i data-feather="eye" />
                  </a>
                  <a
                    :href="field.url"
                    target="_blank"
                    class="me-50"
                  >
                    <i data-feather="edit-2" />
                  </a>
                </template>
                <a
                  v-else
                  :href="field.url"
                  target="_blank"
                  class="me-50"
                >
                  <i data-feather="eye" />
                </a>

                <a
                  :href="$router.resolve({name: getCurrentRouteName, params: {id: field.id}, query: {host: filters.host.id, actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser}}).href"
                  target="_blank"
                >
                  <i data-feather="external-link" />
                </a>
              </td>
            </template>
          </CompleteTable>
        </div>
        <hr>
        <div class="small-table">
          <CompleteTable
            :sorted-field="sortedField"
            :data="archived"
            :sort-mapping="sortMapping"
            :title="'archived documents'"
            :total="archived.length"
            :fetch-path="''"
            :export-path="''"
            :filter-path="''"
            :hide-bottom="true"
          >
            <template v-slot="{field}">
              <td class="text-end">
                <template v-if="field.need_feedback_bool">
                  <a
                    v-if="field.response && field.response.response"
                    :href="field.response.response.url"
                    target="_blank"
                    class="me-50"
                  >
                    <i data-feather="eye" />
                  </a>
                  <a
                    :href="field.url"
                    target="_blank"
                    class="me-50"
                  >
                    <i data-feather="edit-2" />
                  </a>
                </template>
                <a
                  v-else
                  :href="field.url"
                  target="_blank"
                  class="me-50"
                >
                  <i data-feather="eye" />
                </a>

                <a
                  :href="$router.resolve({name: getCurrentRouteName, params: {id: field.id}, query: {host: filters.host.id, actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser}}).href"
                  target="_blank"
                >
                  <i data-feather="external-link" />
                </a>
              </td>
            </template>
          </CompleteTable>
        </div>
        <Export />
        <Search />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../partials/components/CompleteTable.vue'
import Export from './partials/Export.vue'
import Search from './partials/Search.vue'
import CaeSection from './partials/CaeSection.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    Export,
    Search,
    CaeSection,
  },
  data() {
    return {
      hasHistory: true,
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Title',
          checked: true,
          order: 1,
        },
        {
          name: 'Section',
          checked: true,
          order: 2,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 3,
        },
        {
          name: 'Year',
          checked: true,
          order: 4,
        },
        {
          name: 'Renew',
          checked: true,
          order: 4,
        },
        {
          name: 'Need feedback',
          checked: true,
          order: 6,
        },
        {
          name: 'Deadline',
          checked: true,
          order: 7,
        },
        {
          name: 'Status',
          checked: true,
          order: 8,
        },
        {
          name: 'Updated',
          checked: true,
          order: 9,
        },
      ],
      sortMapping: {
        Title: 'title',
        Section: 'section.name',
        Subsection: 'subsection_table',
        Year: 'year',
        Renew: 'renew_year',
        Updated: 'uploaded',
        Deadline: 'deadline',
        Status: 'status',
        'Need feedback': 'need_feedback',
      },
      sortMappingActive: {
        Title: 'title',
        Section: 'section.name',
        Subsection: 'subsection_table',
        Year: 'year',
        Renew: 'renew_year',
        Updated: 'uploaded',
        Deadline: 'deadline',
        Status: 'status',
        'Need feedback': 'need_feedback',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      section: 'cae/item',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
      institution: 'cae/institution',
    }),
    getCurrentRouteName() {
      if (localStorage.getItem('currentRoute').includes('admin.occupational-hazards')) {
        return 'admin.occupational-hazards.view-document'
      }

      return 'cae.view-document'
    },
    archived() {
      return this.section && this.section.documents ? this.section.documents.filter(e => e.active !== true) : []
    },
    active() {
      return this.section && this.section.documents ? this.section.documents.filter(e => e.active === true) : []
    },
    id() {
      return this.$route.params.id
    },
  },
  watch: {
    id() {
      this.$store.dispatch('modals/fetchUserFields', 'archived_files')
      this.$store.dispatch('cae/fetchId', { id: this.id, filters: this.filters })
    },
  },
  async mounted() {
    this.hasHistory = window.history.length > 1
    let institution = null
    if (!this.institution) {
      institution = { id: this.$route.query.host }
    } else {
      institution = this.institution
    }

    if (!institution) {
      this.$router.push({ name: 'cae.index' })
    }

    this.$store.dispatch('filters/saveFilters', {
      value: institution,
      field: 'host',
    })
    await this.$store.dispatch('modals/fetchUserFields', 'archived_files')

    if (this.$route.query.prefilterUser) {
      this.$store.dispatch('filters/saveFilters', {
        value: { id: this.$route.query.prefilterUser },
        field: 'researcher',
      })
    } else {
      this.$store.dispatch('filters/saveFilters', {
        value: null,
        field: 'researcher',
      })
    }

    await this.$store.dispatch('cae/fetchId', { id: this.id, filters: this.filters })

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'archived_files',
      })
    }
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
  },
}
</script>
