<template>
  <div>
    <div
      id="offcanvasChapters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden', width: width }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="file" /></span>
          Export
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body offcanvas-body--view">
        <h4>Select an option:</h4>

        <div class="row custom-options-checkable g-1 mb-1">
          <div class="col">
            <input
              id="customOptionsCheckableRadios1"
              class="custom-option-item-check"
              type="radio"
              name="customOptionsCheckableRadios"
              checked
            >
            <label
              class="custom-option-item p-1"
              for="customOptionsCheckableRadios1"
              @click="type = 1"
            >
              <span class="d-flex justify-content-between flex-wrap mb-50">
                <span class="fw-bolder">All files</span>
              </span>
              <small class="d-block">Export all files in this category.</small>
            </label>
          </div>
        </div>
        <div class="row custom-options-checkable g-1 mb-1">
          <div class="col">
            <input
              id="customOptionsCheckableRadios2"
              class="custom-option-item-check"
              type="radio"
              name="customOptionsCheckableRadios"
              value=""
            >
            <label
              class="custom-option-item p-1"
              for="customOptionsCheckableRadios2"
              @click="type = 2"
            >
              <span class="d-flex justify-content-between flex-wrap mb-50">
                <span class="fw-bolder">Only active files</span>
              </span>
              <small class="d-block">Export only active files.</small>
            </label>
          </div>
        </div>
        <div class="row custom-options-checkable g-1 mb1">
          <div class="col">
            <input
              id="customOptionsCheckableRadios3"
              class="custom-option-item-check"
              type="radio"
              name="customOptionsCheckableRadios"
              value=""
            >
            <label
              class="custom-option-item p-1"
              for="customOptionsCheckableRadios3"
              @click="type = 3"
            >
              <span class="d-flex justify-content-between flex-wrap mb-50">
                <span class="fw-bolder">Only archive files</span>
              </span>
              <small class="d-block">Export only archived files.</small>
            </label>
          </div>
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          v-if="!loading"
          type="button"
          class="btn btn-success mb-1 d-grid w-100"
          @click="exportFiles"
        >
          Export
        </button>
        <button
          v-if="loading"
          class="btn btn-outline-success mb-1 w-100"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="ms-25 align-middle">Exporting...</span>
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    tableId: {
      type: Number,
      required: false,
      default: null,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      type: 1,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/caeExport',
      tableClicked: 'modals/tableClicked',
      section: 'cae/item',
      filters: 'filters/filters',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleCaeExport', false)
    },
    async exportFiles() {
      this.loading = true
      await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/cae/${this.section.id}/export-files/${this.type}`, this.filters, {
        responseType: 'arraybuffer',
      }).then(response => {
        this.loading = false
        // Create a Blob from the received response data
        const blobData = new Blob([response.data], { type: 'octet/stream' })

        // Create a URL for the blob data
        const blobUrl = window.URL.createObjectURL(blobData)

        // Create a link and initiate the download
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = `${this.section.name} - files.zip`
        link.click()

        Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/cleanup`)
      }).catch(error => {
        this.$toastr.error('', 'No files matching filter')
        this.loading = false
        console.log(error)
      })
    },
  },
}
</script>
